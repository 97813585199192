// .layout {
// 	height: 100vh;
// }
.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	width: 100%;
	z-index: 10;
	padding-left: 16px;
}
.siteLayoutBackground {
	padding: 24px;
	margin: 0px;
	min-height: 500px;
}
.sider {
	padding-top: 60px;
	height: 100vh;
	position: fixed;
	overflow-y: auto;
	left: 0;
}
