.tableBase {
	background-color: #8E949F !important;
}
.tableAllowed {
	background-color: #9CA9C1 !important;
}
.tableApplied {
	background-color: #C6C9CF !important;
}
.tableActions {
	background-color: #ECEEEF !important;
}