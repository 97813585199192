.classification {
	background-color: #8E949F !important;
}
.configuration {
	background-color: #9CA9C1 !important;
}
.finish {
	background-color: #C6C9CF !important;
}
.pricing {
	background-color: #ECEEEF !important;
}